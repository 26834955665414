import React, { Component } from 'react';
import { Header } from '../layout/Header';
import { Helmet } from "react-helmet";
export class Study_medicine_in_russia_rostov_on_don_city extends Component {
  render() {

    return (
      <div className="site-main">
        <Helmet>
          <title>Study Medicine in Rostov on Don city - Rostov State Medical University, Russia</title>
          <meta name="description" content="Study Medicine in Rostov-on-Don city; Rostov state medical university - one of the oldest and reputed medical university of Russia since USSR time. MBBS Course in English or in Russian Medium." />
          <link rel="canonical" href="https://rostgmu.net/study-medicine-in-russia-rostov-on-don-city/" />
        </Helmet>

        <section className="hero-section overlay bg-cover">
          <div className="container">
            <div className="hero-slider">
              <div className="hero-slider-item">
                <div className="row">
                  <div className="col-md-8">
                    <h1 className="text-white" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".1">About Rostov-on-Don city</h1>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row list-items">
              <div className="col-md-12 order-2 order-md-1">
                <img src='images/rostov-on-don-capital-city-of-russia.webp' className='img-fluid' alt='' />
                <h1>Rostov city at a glance (Capital of South Russia)</h1>
                <p><strong>Rostov-on-Don</strong> s a port city and the administrative center of Rostov Oblast and the Southern Federal District of Russia. It lies to the southeast of the East European Plain, on the Don River, 32 kilometers from the Sea of Azov. The southwestern suburbs of the city about the Don River delta.</p>
                <p><strong>City districts</strong>: Rostov-on-Don is divided into eight city districts: Kirovsky, Leninsky, Oktyabrsky, Pervomaysky, Proletarsky, Sovetsky, Voroshilovsky.</p>
                <p><strong>Symbols: </strong>In December 1996, Rostov-on-Don adopted a coat of arms, a flag, and a mayoral decoration as the symbols of the town. The first coat of arms of Rostov-on-Don was designed in 1811 and approved by the Tsar. In 1904, some changes were made. One lasting oil painting of the coat-of-arms is kept in the regional local history museum but its accuracy and authenticity are uncertain. In June 1996, the Rostov-on-Don City Duma adopted a variant of the coat-of-arms in which a tower represents the St. Dimitry Rostovsky Fortress. The ancient Russian arms reference the role Rostov played in the defense of Russia&rsquo;s borders. The coat-of-arms adorn the mayor&rsquo;s decoration but all other cases of its use are first considered for approval by the City Duma.                                         </p>
                <p><strong>Flag:</strong> The flag of Rostov-on-Don was approved by the Duma on September 20, 1864. At the end of the 19th and beginning of the 20th centuries the home guard regiments, which defended the Southern borders of Russia, were raised under this flag. The &ldquo;Flag of Rostov&rdquo; is kept in the town&rsquo;s municipal building under glass. Its length is 1,370 millimeters (54 in) and its width, 850 millimeters (33 in). The flag is taken out of the building only on Victory Day and Rostov-on-Don Day by a guard of honor.</p>
                <p><strong>Education: </strong>Rostov-on-Don hosts higher educational establishments, including universities, academies, secondary schools, or vocational training including colleges, technical schools, specialized schools, and elementary schools of vocational training including lyceums, professional colleges, and schools of general education.</p>
                <p><strong>Libraries: </strong>The Don State Public Library, Central Library named after Maxim Gorky, Regional Children&rsquo;s Library named after V.M. Velichkina, Rostov Regional Special Library for the Blind, Scientific Library of the Medical University, Central State Children&rsquo;s Library named after Lenin, Children&rsquo;s Library named after A.S. Pushkin, Children&rsquo;s Library named after Mayakovsky, South-Russian Don State Public Library</p>
                <p><strong>Theaters: </strong>Maxim Gorky Academic Drama Theater, Rostov State Puppet Theater, Rostov Regional Academic Theater of the Youth, Rostov Musical Theater, Philharmonic center, Theater 18+, Kim Nazaretov jazz center. In the Academic Drama Theater named after Maxim Gorky works Mikhail Bushnov, who is the national artist of the USSR and an honorary citizen of Rostov-on-Don.</p>
                <p><strong>Museums: </strong>Museum of Local Lore, Rostov Regional Museum of Fine Arts, Museum of Fine Arts on Dmitrovskaya, Museum of Russian &amp; Armenian Friendship, Pioneer and Railway Museum, and Children&rsquo;s Railway. The small collections of the Art Gallery and the Museum of Arts include some works by Repin, Surikov, Perov, Levitan, Aivazovsky as well as of modern Rostov artists.</p>
                <p><strong>Other facilities: </strong>Other facilities include seven stadiums, a Palace of Sports, a circus, a zoo, botanical gardens, and parks. Rostov-on-Don hosts the North Caucasian Science Center and research institutes.</p>
                <p><strong>Sports: </strong>Rostov-on-Don is one of the host cities for the 2018 FIFA World Cup. A huge development is planned on the left bank of the Don River, which includes a soccer stadium to host the games during the championship. FC Rostov football (1930)-1st, SKA Rostov football (1937)-3rd, Rostov-on-Don basketball 2006-2nd, Rostov-on-Don Handball 1965-1st, HC Rostov Ice Hockey 2004-3rd.</p>


              </div>
            </div>

            <div className="row list-items align-items-center">
              <div className="col-md-4 order-2 order-md-1">

                <h3>3D view of Rostov-on-Don city <br /> Beautiful city of Southern Russia:-</h3>
                <p><iframe title="Rostov City At A Glance | 3D View Of Beautiful City | Rostov State Medical University, Russia" width="100%" height="360" src="https://www.youtube.com/embed/oxb-VKDPpp8?start=1&#038;feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>

              </div>


              <div className="col-md-4 order-2 order-md-1">
              <h3>Exploring Stunning City Views at Rostov State Medical University</h3>
                <p><iframe width="100%" height="360" src="https://www.youtube.com/embed/0_osKboyOYU?si=0EQUyzwPizF2rhDK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></p>
  </div>
  

              <div className="col-md-4 order-2 order-md-1">

                <h3>The capital city of South Russia <br /> Rostov-on-Don:-</h3>
                <p><iframe title="Rostov-on-Don City | RostSMU | Get Direct Admissions For 2024 | RREC Official Representative" width="100%" height="360" src="https://www.youtube.com/embed/WXsS5xa3ELw?start=45&#038;feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>

              </div>

            </div>


          </div>
        </section>


      </div>


    )
  }

}

export default Study_medicine_in_russia_rostov_on_don_city;