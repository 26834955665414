import React, { Component } from 'react';
import { Header } from '../layout/Header';
import { Helmet } from "react-helmet";
export class Frequently_asked_questions extends Component {
  render() {

    return (
      <div className="site-main">
        <Helmet>
          <title>Frequently asked questions by students - About Rostov Medical University</title>
          <meta name="description" content="Dear applicants, welcome to our university, here we have replied all the queries related to Rostov state medical university, Russia. Interesting students may read FAQ for their information." />
          <link rel="canonical" href="https://rostgmu.net/frequently-asked-questions/" />
        </Helmet>

        <section className="hero-section overlay bg-cover">
          <div className="container">
            <div className="hero-slider">
              <div className="hero-slider-item">
                <div className="row">
                  <div className="col-md-8">
                    <h1 className="text-white" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".1">Frequently asked questions
                    </h1>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row list-items">
              <div className="col-md-12 order-2 order-md-1">

                <h2>Frequently asked questions by Students / Parents, about RostSMU</h2>


                <div id="accordion">
                  <div className="card">
                    <div className="card-header" id="h1">
                      <h5 className="mb-0">
                        <button className="btn btn-link" data-toggle="collapse" data-target="#c1" aria-expanded="true" aria-controls="c1">
                          Is the medical degree, awarded by Rostov State Medical University, recognized in Asia, Africa, UAE & other countries?
                        </button>
                      </h5>
                    </div>

                    <div id="c1" className="collapse show" aria-labelledby="h1" data-parent="#accordion">
                      <div className="card-body">
                        Yes, the medical degree, awarded by Rostov State Medical University, is recognized, As per the rules of each country according to their Medical Councils, a foreign medical degree (equivalent to MBBS) whose name and the degree awarded are enlisted in the World Directory of Medical Schools, published by the World Health Organization (WHO) is a recognized medical degree. Rostov State Medical University and degree awarded by it are enlisted in the World Directory Of Medical Schools, published by the World Health Organization, and thus are recognized by the Medical Councils.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="h2">
                      <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#c2" aria-expanded="false" aria-controls="c2">
                          What are the minimum requirements for admission to Rostov State Medical University?
                        </button>
                      </h5>
                    </div>
                    <div id="c2" className="collapse" aria-labelledby="h2" data-parent="#accordion">
                      <div className="card-body">
                        As per the Graduate Medical Education Regulations, the minimum qualification for foreign students for admission to any Foreign Medical University is as follows:
                        <ul>
                          <li>He/she shall complete the age of 17 years on or before the 31st of December, of the year of admission to the MBBS course (General Medicine Faculty).</li>
                          <li>To get admission to RSMU, the study must have 65% in each subject: English, Chemistry, and Biology.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="h3">
                      <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#c3" aria-expanded="false" aria-controls="c3">
                          Is there any entrance examination for getting admission to Rostov State Medical University?
                        </button>
                      </h5>
                    </div>
                    <div id="c3" className="collapse" aria-labelledby="h3" data-parent="#accordion">
                      <div className="card-body">
                        Yes, there is an entrance examination, the student must pass the Chemistry and Biology entrance test to become a student of RostSMU first year. Admissions are on a first come first served system.
                      </div>
                    </div>
                  </div>


                  <div className="card">
                    <div className="card-header" id="h4">
                      <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#c4" aria-expanded="false" aria-controls="c3">
                          Do I have to undergo a one-year Russian Language Course (Preparatory faculty for foreign students)?
                        </button>
                      </h5>
                    </div>
                    <div id="c4" className="collapse" aria-labelledby="h4" data-parent="#accordion">
                      <div className="card-body">
                        Yes, If you have select to study Medicine in RostSMU in Russian medium then only you have to undergo the one-year Russian Language Course. For English Medium no need for a Russian Language course separately. The Russian Language is necessary to know because during Medical education you have to interact with local patients and so you must know the local language i.e Russian. That is why you are given the Russian language classNamees as a subject during the first, second, and third years, even if you are studying in English Medium. The knowledge of Russian also helps you in your daily life.
                      </div>
                    </div>
                  </div>



                  <div className="card">
                    <div className="card-header" id="h5">
                      <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#c5" aria-expanded="false" aria-controls="c3">
                          What type of climate is in Rostov-on-Don?
                        </button>
                      </h5>
                    </div>
                    <div id="c5" className="collapse" aria-labelledby="h5" data-parent="#accordion">
                      <div className="card-body">
                        There are 4 seasons in Russia. Spring (March to May), Summer (June to August), Autumn (September to November), and Winter (December to February). The climate of Rostov is temperate continental with cool summer and mild winter. The temperature in summer is up to +30 and in winter about -10 to -25 degrees centigrade.
                      </div>
                    </div>
                  </div>



                  <div className="card">
                    <div className="card-header" id="h6">
                      <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#c6" aria-expanded="false" aria-controls="c3">
                          How are we protected from -10 to -25 degrees in winters?
                        </button>
                      </h5>
                    </div>
                    <div id="c6" className="collapse" aria-labelledby="h6" data-parent="#accordion">
                      <div className="card-body">
                        All buildings in Russia, including the University and the Hostel buildings, public transports and shops, etc. are centrally heated. The government takes care of the heating system. Students are well-advised by our representative, how to wear and keep warm.
                      </div>
                    </div>
                  </div>


                  <div className="card">
                    <div className="card-header" id="h7">
                      <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#c7" aria-expanded="false" aria-controls="c3">
                          How do I travel to my country from Russia?
                        </button>
                      </h5>
                    </div>
                    <div id="c7" className="collapse" aria-labelledby="h7" data-parent="#accordion">
                      <div className="card-body">
                        There are many airlines operating on the route from Moscow to different cities of several countries from various airports of Moscow and the local & international airport from Rostov-on-Don city. RREC Team our official representative of International students helps in purchasing air tickets with student concession.
                      </div>
                    </div>
                  </div>


                  <div className="card">
                    <div className="card-header" id="h8">
                      <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#c8" aria-expanded="false" aria-controls="c3">
                          How do I travel to Moscow from Rostov-on-Don?
                        </button>
                      </h5>
                    </div>
                    <div id="c8" className="collapse" aria-labelledby="h8" data-parent="#accordion">
                      <div className="card-body">
                        Rostov-on-Don is not far from Moscow. Rostov is well connected to Moscow by train, bus, flights. Students usually travel to Moscow airport or direct to Platov International airport Rostov-on-Don. One of the top and best International Airport of Russia in the city: Platov International Airport, daily flights as per time table. Our official representative organizes group travel to the airport by bus or minibus, which is comparably quite cheap.
                      </div>
                    </div>
                  </div>


                  <div className="card">
                    <div className="card-header" id="h9">
                      <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#c9" aria-expanded="false" aria-controls="c3">
                          Where will I live?
                        </button>
                      </h5>
                    </div>
                    <div id="c9" className="collapse" aria-labelledby="h9" data-parent="#accordion">
                      <div className="card-body">
                        You will live in a student hostel especially for foreign students. Hostels are the best hostels of Rostov State Medical University in all Russia. The system of residence is separate for boys and girls, 3 seated big rooms, upgraded comforts, central heating, full furniture with beddings, shower room, toilet, washbasin room, security guards, laundry, cleaning and washing personnel, reading rooms, 24 hours medical center.
                      </div>
                    </div>
                  </div>


                  <div className="card">
                    <div className="card-header" id="h10">
                      <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#c10" aria-expanded="false" aria-controls="c3">
                          If I do not like to live in the hostel, can I live in some rented flat or somewhere else?
                        </button>
                      </h5>
                    </div>
                    <div id="c10" className="collapse" aria-labelledby="h10" data-parent="#accordion">
                      <div className="card-body">
                        No, if you got admission to the Rostov State Medical University then you have to live in the hostel. This is for your well being only. When living in the hostel, you are inside the campus, your classNamees are nearby, you are safe, in the hostel, there is a studious environment, students live in a more disciplined manner, there is a strictly controlled system keeping a watch on students. Rest depend on students or his/her parents.
                      </div>
                    </div>
                  </div>


                  <div className="card">
                    <div className="card-header" id="h11">
                      <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#c11" aria-expanded="false" aria-controls="c3">
                          Do we have a gym in our university or in the city?
                        </button>
                      </h5>
                    </div>
                    <div id="c11" className="collapse" aria-labelledby="h11" data-parent="#accordion">
                      <div className="card-body">
                        Yes, we have the gym, operating separately for boys and girls. We have all kinds of machines for them. Personal trainers are giving you guidelines for your exercises.
                      </div>
                    </div>
                  </div>


                  <div className="card">
                    <div className="card-header" id="h12">
                      <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#c12" aria-expanded="false" aria-controls="c3">
                          Do I have to clean my toilet and shower room myself?
                        </button>
                      </h5>
                    </div>
                    <div id="c12" className="collapse" aria-labelledby="h12" data-parent="#accordion">
                      <div className="card-body">
                        No, a cleaning woman will come daily and clean your toilet, washbasin room, and shower room.
                      </div>
                    </div>
                  </div>



                  <div className="card">
                    <div className="card-header" id="h13">
                      <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#c13" aria-expanded="false" aria-controls="c3">
                          Can I do any part-time job?
                        </button>
                      </h5>
                    </div>
                    <div id="c13" className="collapse" aria-labelledby="h13" data-parent="#accordion">
                      <div className="card-body">
                        No, you will be in Russia on a student visa and so you are not allowed to take employment. Furthermore, you will be a medical student and a medical student does not have that much free time to take employment.
                      </div>
                    </div>
                  </div>


                  <div className="card">
                    <div className="card-header" id="h14">
                      <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#c14" aria-expanded="false" aria-controls="c3">
                          Can a foreign student open a bank account?
                        </button>
                      </h5>
                    </div>
                    <div id="c14" className="collapse" aria-labelledby="h14" data-parent="#accordion">
                      <div className="card-body">
                        Yes, a foreign student can open a bank account.
                      </div>
                    </div>
                  </div>




                  <div className="card">
                    <div className="card-header" id="h15">
                      <h5 className="mb-0">
                        <button className="btn btn-link collapsed in" data-toggle="collapse" data-target="#c15" aria-expanded="false" aria-controls="c3">
                          Can I pay my tuition fees before arriving at university?
                        </button>
                      </h5>
                    </div>
                    <div id="c15" className="collapse" aria-labelledby="h15" data-parent="#accordion">
                      <div className="card-body">
                        Yes, a student can pay their fees to a university bank account or arrival in Russia.
                      </div>
                    </div>
                  </div>


                  <div className="card">
                    <div className="card-header" id="h16">
                      <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#c16" aria-expanded="false" aria-controls="c3">
                          Can I pay my fee in the shape of the international bank draft or bank cheque was drawn in favor of the university?
                        </button>
                      </h5>
                    </div>
                    <div id="c16" className="collapse" aria-labelledby="h16" data-parent="#accordion">
                      <div className="card-body">
                        No, the fee is not accepted in the shape of an international bank draft or bank cheque drawn in favor of the university. The fee is accepted in cash or through banks, only before 10th September each year up-to your completion of the course.
                      </div>
                    </div>
                  </div>


                  <div className="card">
                    <div className="card-header" id="h17">
                      <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#c17" aria-expanded="false" aria-controls="c3">
                          If I need money in Russia, how my parents can send me money?
                        </button>
                      </h5>
                    </div>
                    <div id="c17" className="collapse" aria-labelledby="h17" data-parent="#accordion">
                      <div className="card-body">
                        The best way is to use international ATM/debit cards. For this purpose, a student should open a bank account in their native country in a bank that is offering an international ATM/debit card. Then, whenever he needs money, his/her parents will just deposit the required amount in this account and the student will withdraw the amount from any ATM machine of any Russian bank.
                      </div>
                    </div>
                  </div>


                  <div className="card">
                    <div className="card-header" id="h18">
                      <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#c18" aria-expanded="false" aria-controls="c3">
                          How my parents will call me?
                        </button>
                      </h5>
                    </div>
                    <div id="c18" className="collapse" aria-labelledby="h18" data-parent="#accordion">
                      <div className="card-body">
                        Every foreign student is advised to have a mobile phone. The mobile phone system is highly developed in Russia and your parents can easily contact you. There is a lot of cheap calling software providing by Russian operators of communications, student have to buy ownself for his/her use.
                      </div>
                    </div>
                  </div>


                  <div className="card">
                    <div className="card-header" id="h19">
                      <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#c19" aria-expanded="false" aria-controls="c3">
                          Will I have an Internet facility?
                        </button>
                      </h5>
                    </div>
                    <div id="c19" className="collapse" aria-labelledby="h19" data-parent="#accordion">
                      <div className="card-body">
                        Yes, you will have an Internet facility.
                      </div>
                    </div>
                  </div>


                  <div className="card">
                    <div className="card-header" id="h20">
                      <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#c20" aria-expanded="false" aria-controls="c3">
                          Can my parents visit me?
                        </button>
                      </h5>
                    </div>
                    <div id="c20" className="collapse" aria-labelledby="h20" data-parent="#accordion">
                      <div className="card-body">
                        Yes, your parents are most welcome to visit you and the university. They have to take a tourist visa from their native country or from Russia and have to take care of all their visit own self, we are not responsible for anything.
                      </div>
                    </div>
                  </div>


                  <div className="card">
                    <div className="card-header" id="h21">
                      <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#c21" aria-expanded="false" aria-controls="c3">
                          When are my vacations? Can I visit my country during my vacation?
                        </button>
                      </h5>
                    </div>
                    <div id="c21" className="collapse" aria-labelledby="h21" data-parent="#accordion">
                      <div className="card-body">
                        Winter vacations are only 15 days in January or February, but summer vacations are two months (July and August). You are advised to visit your country during your summer vacation.
                      </div>
                    </div>
                  </div>


                  <div className="card">
                    <div className="card-header" id="h22">
                      <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#c22" aria-expanded="false" aria-controls="c3">
                          Is the hostel open during these vacations? Do I have to leave the hostel during the vacation?
                        </button>
                      </h5>
                    </div>
                    <div id="c22" className="collapse" aria-labelledby="h22" data-parent="#accordion">
                      <div className="card-body">
                        Yes, the hostel is open during vacations. No, you do not have to leave the hostel. You can stay at the hostel.
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>


            <div className="row list-items align-items-center">
              <div className="col-md-6 order-2 order-md-1">

                <div class="bg-green text-center">
                  <h3>If you are going to study in Russia, if you are interested in Russian culture, history and language<br />
                    WELCOME to ROSTOV STATE MEDICAL UNIVERSITY </h3>
                </div>
              </div>
              <div className="col-md-6 order-2 order-md-1">
                <div class="bg-red text-center">
                  <p>Rostov State Medical University – Study MBBS in Russia – Direct MBBS Admission in Russia 2024</p>
                </div>
              </div>

            </div>

          </div>
        </section>
      </div>


    )
  }

}

export default Frequently_asked_questions;