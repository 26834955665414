import React, { Component } from 'react';
import { Header } from '../layout/Header';
import { Helmet } from "react-helmet";
export class Rostov_state_medical_university_faculties_and_fees extends Component {
  render() {

    return (
      <div className="site-main">
        <Helmet>
          <title>Fee structure - Rostov State Medical University </title>
          <meta name="description" content="Tuition Fee for the medicine (MBBS), stomatology, pharmacy, preparatory course for the session 2024-25. Fee structure generally includes tuition fees, accommodation, medical insurance. Join RostSMU for the 2024-25 academic year." />
          <link rel="canonical" href="https://rostgmu.net/rostov-state-medical-university-faculties-and-fees/" />
        </Helmet>
        <section className="hero-section overlay bg-cover">
          <div className="container">
            <div className="hero-slider">
              <div className="hero-slider-item">
                <div className="row">
                  <div className="col-md-8">
                    <h1 className="text-white" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".1">RSMU faculties &amp; fees</h1>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row list-items">
              <div className="col-md-12 order-2 order-md-1">
                <img src='images/rostov-state-medical-university-faculties-and-fees.webp' className='img-fluid' alt='' />

                <h1>Rostov State Medical University: Faculties &amp; Fee structure</h1>
                <p>Nowadays there are six faculties that provide training to foreign students. Graduates of <a href="https://thedoctorsiea.in/rostov-state-medical-university/">Rostov State Medical University</a> have all the necessary knowledge, skills, and qualifications that enable them to succeed in their future careers. They are in demand in the labor market.</p>
                <p>Admission to the first year of training in 2024 for all specialties of higher education is carried out at the <a href="https://rostovstatemedicaluniversity.com/">Rostov State Medical University</a> by the results of two entrance examinations, including</p>
                <ul>
                  <li>Chemistry</li>
                  <li>Biology  </li>
                </ul>

                <h3>Faculties for Foreign students:</h3>
                <ul>
                  <li>Faculty of General Medicine; 6 yrs</li>
                  <li>Faculty of Dentistry; 5 yrs</li>
                  <li>Faculty of Pediatrics; 5 yrs</li>
                  <li>Faculty of Pharmacy; 5 yrs</li>
                  <li>Faculty of Postgraduate and Continuous Education; 2-3 yrs</li>
                  <li>Faculty of Preparatory Course; 1 yr / 8-10 months</li>
                </ul>

                <h3>Fee Structure for the academic year 2024:</h3>
                <p>The fee structure for different faculties of the Rostov State Medical University is given separately as a &lsquo;Fee structure certificate&rsquo;. The fee structure is stated in rubles or in USD Dollars. The fee has to be paid in Russia every year not later than the 10th of September for the current academic year. The fee is only to be paid on arrival or to the bank account of the university. Once a student is admitted to any faculty of the Rostov State Medical University, his/her tuition fee are settled for entire course. 6-12% tuition fee can be increased every year for all faculties. </p>
                <h3>TUITION FEE FOR PREPARATORY FACULTY (RUSSIAN LANGUAGE COURSE):</h3>
                <ul>
                  <li>Tuition Fee- 194 000 rubles.</li>
                </ul>
                <h3>TUITION FEE FOR GENERAL MEDICINE FACULTY (MBBS):</h3>
                <ul>
                  <li>For English Medium: 326 000 rubles.</li>
                  <li>For Russian Medium: 224 700 rubles</li>
                </ul>
                <h3>TUITION FEE FOR DENTISTRY FACULTY (BDS):</h3>
                <ul>
                  <li>Tuition Fee- 257 100 rubles</li>
                </ul>
                <h3>TUITION FEE FOR PHARMACY FACULTY:</h3>
                <ul>
                  <li>Tuition Fee- 224 700 rubles</li>
                </ul>
                <h3>TUITION FEE FOR PEDIATRICS FACULTY</h3>
                <ul>
                  <li>Tuition Fee- 224 700 rubles</li>
                </ul>
                <h3>TUITION FEE FOR POSTGRADUATE FACULTY</h3>
                <ul>
                  <li>Tuition Fee for Post Graduate Course-Starts from 4500-6000 USD Dollars per year, tuition fee depends upon the selected specialty in post-graduation.</li>
                </ul>

                <h3>Hostel Fee:</h3>
                <ul>
                  <li>Hostel Fee – 320 - 400  USD Dollars per year.</li>
                  <li>This fee is in accordance with the current cost of communal charges (electricity, water, gas, heating system, etc.) and therefore can be changed every year depending upon the cost of communal charges for that year.</li>
                </ul>

                <h3>Compulsory Medical Insurance:</h3>
                <ul>
                  <li>Compulsory Medical Insurance: 100-120 USD Dollars per year.</li>
                  <li>As per the laws of the Russian Federation, all students have to take a compulsory medical insurance policy. The rate of the policy is decided by the insurance companies and so can be different every year. </li>
                </ul>

                <p>&rdquo;Doctor&rsquo;s not only in professional but also in social life have a prestigious and respectful status in society. This is the most humanitarian profession on earth, hence it is correctly said that &ldquo;DOCTOR&rsquo;S ARE LIVING GOD&rsquo;S ON EARTH&rdquo;. But A good student always has the best teachers and these masters of Medicine are very rare to be found. &ldquo;ROSTOV STATE MEDICAL UNIVERSITY&rdquo; provides a world-class teaching facility with the best teachers around the world. So you decide your own master, who shall they be? It&rsquo;s in your hands to make your future as bright as possible.&rdquo;</p>


              </div>
            </div>

            <div className="row list-items align-items-center">
              
              <div className="col-md-12 order-2 order-md-1">
                <div className='bg-green text-center'>
                  <h3>Online Applicants</h3>
                  <p> who wish to <a href="https://thedoctorsiea.in/">study MBBS in Russia</a> at <a href="https://rrecrostov.com/"><strong>Rostov State Medical University</strong> </a>should fill the Application form, and send the scan copies of the passport and secondary school certificate (NEET qualified certificate is mandatory for Indian students for 2024 admissions) to <a href="mailto:rostsmu@gmail.com"><strong>rostsmu@gmail.com</strong></a></p>
                </div>
              </div>

            </div>

            <div className="row list-items align-items-center">
              <div className="col-md-6 order-2 order-md-1">

                <h3>Here you can visit the university campus, students, departments</h3>
                <p><iframe title="Rostov State Medical University | Russia | Official" width="100%" height="360" src="https://www.youtube.com/embed/aWRUegjs4N8?start=167&#038;feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>

              </div>
               <div className="col-md-6 order-2 order-md-1">
             
                  <h3>Rostov State Medical University Tour | Official Channel for International Students</h3>
               
                  <p><iframe width="100%" height="360" src="https://www.youtube.com/embed/v8tiR_kbzfA?si=ZJeZaNuUoW2B0_Nq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></p>
            
              </div>

            </div>


          </div>
        </section>


      </div>


    )
  }

}

export default Rostov_state_medical_university_faculties_and_fees;