import React, { Component } from 'react';
import { Header } from '../layout/Header';
import { Helmet } from "react-helmet";
export class Representative_of_foreign_students extends Component {
  render() {

    return (
      <div className="site-main">
        <Helmet>
          <title>Representative of Indian and International Students-RostSMU, Russia</title>
          <meta name="description" content="Dear applicants, welcome to study medicine at Rostov state medical university. To know more about our university, course details, fee structure, admission procedure contact our representative." />
          <link rel="canonical" href="https://rostgmu.net/representative-of-foreign-students/" />
        </Helmet>

        <section className="hero-section overlay bg-cover">
          <div className="container">
            <div className="hero-slider">
              <div className="hero-slider-item">
                <div className="row">
                  <div className="col-md-8">
                    <h1 className="text-white" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".1">Representatives of foreign students</h1>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row list-items">
              <div className="col-md-12 order-2 order-md-1">
                <img src='images/representative-of-foreign-students.webp' className='img-fluid' alt='' />
                <h1>Rostov State Medical University </h1>
                <h2>(Ростовский Государственный Медицинский Университет)</h2>
                <p><strong>Address:</strong> 29, Nakhichevansky Lane, Rostov-on-Don, 344022</p>

                <p><strong>Dean office of foreign students, residents, and postgraduates </strong><strong>Address:</strong> 119/80 Suvorova street, Rostov-on-Don,344022</p>
                <p>Admission intake is open for the 2024/25 academic year for all faculties. The following organization has been granted rights to represent the university. International students are requested to contact our representative directly for more information. We work closely with our International student representative, who is able to provide you with additional information about Rostov State Medical University and give you help and advice. Admission inquiries from students, from all over countries solicited. Contact our official representative for more details.</p>
                <p><strong>For spot, admissions write to us a</strong>t <a href="mailto:rostsmu@gmail.com">rostsmu@gmail.com</a></p>

              </div>
            </div>

            <div className="row list-items align-items-center">
              <div className="col-md-12 order-2 order-md-1 red">

                <h3>WELCOME TO STUDY MEDICINE IN ROSTSMU, RUSSIA - ADMISSIONS ARE OPEN FOR 2024.</h3>
             
                <div className='bg-green text-center'>
                <h1 className='text-white mr-0'><strong>OUR INTERNATIONAL PARTNER</strong></h1>

<p><strong>♦<a href="https://rrecrostov.com/">Reliable Russian Educational Consultants Pvt. Ltd</a>♦</strong></p>
<br/>
<div className='row'>

<div class="col-sm-6">
<h3><strong>Registered office address:</strong></h3>
<p><strong>Office no. 608, 6th floor, Aggarwal Cyber Plaza-1<br/> Netaji Subhash Place, Pitampura, New Delhi - 110034 Delhi, India </strong></p>
</div>
<div class="col-sm-6">
<h3><strong>Corporate office address: </strong></h3>
<p><strong>Office no. 709-710, 7th floor, P.P. Trade Center<br/> Netaji Subhash Place, Pitampura, New Delhi - 110034 Delhi, INDIA</strong></p>
</div>
<div class="col-sm-3"></div>
<div class="col-sm-6 mrp">
<h3><strong>Contacts:</strong></h3>
<p><strong>For Indian Students: +91-7669533991, +91-7042284508 / 509<br/>
Landline: +91-1143215827, Toll-free: 1800-572-5827</strong></p>
<p><strong>For International Students: +7-9515173719, +7-9515190376</strong></p>
<p><strong>E-mail: contact@rrecrostov.com, contact@rrecrussia.com, rrec.educonsultants@gmail.com</strong></p>
<p><strong>Website: www.rrecrostov.com</strong></p>
</div>


</div>



                </div>
              </div>

            </div>


          </div>
        </section>


      </div>


    )
  }

}

export default Representative_of_foreign_students;