import React, { Component } from 'react';
import { Header } from '../layout/Header';
import { Helmet } from "react-helmet";
export class Recognitions_links extends Component {
  render() {

    return (
      <div className="site-main">
        <Helmet>
          <title>Recognitions &amp; links - Rostov state medical university, Russia / 2024-25</title>
          <meta name="description" content="Best campus. Worldwide recognition. Foreign students from 30+ countries, Accredited &amp; Licensed University, World famous Doctors-Professors, High Quality Education, Comfort Environment." />
          <link rel="canonical" href="https://rostgmu.net/recognitions-links/" />
        </Helmet>


        <section className="hero-section overlay bg-cover">
          <div className="container">
            <div className="hero-slider">
              <div className="hero-slider-item">
                <div className="row">
                  <div className="col-md-8">
                    <h1 className="text-white" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".1">Recognitions &amp; links</h1>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row list-items">
              <div className="col-md-12 order-2 order-md-1">

                <h1>Rostov State Medical University, Russia</h1>
                <p>RSMU is approved by the following Medical regulatory bodies of the world as medical university-it permits medical students to apply for getting the license to practice and get the benefit of various scholarships.</p>
                <ul>
                  <li>National Medical Commission India: <a href="https://www.nmc.org.in/" target="_blank">http://www.nmc.org.in/</a> After completion of medical studies in foreign, Indian students can appear in Screen Test named FMGE (Foreign Medical Graduates Examination) and get their license to work in India. We advise all Students from India to take an eligibility certificate from the National Medical Commission India. </li>
                  <li>World health organization: <a href="http://www.who.int/en/" target="_blank">http://www.who.int/en/</a></li>
                  <li>Educational Commission for Foreign Medical Graduates, USA: <a href="http://www.ecfmg.org/" target="_blank">http://www.ecfmg.org/</a></li>
                  <li>Foundation For Advancement Of International Medical Education &amp; Research, USA: <a href="http://www.faimer.org/" target="_blank">http://www.faimer.org/</a></li>
                  <li>Education Ministry of Russia: <a href="http://mon.gov.ru/" target="_blank">http://mon.gov.ru/</a></li>
                  <li>Health Ministry of Russia: <a href="https://minzdrav.gov.ru/en" target="_blank">https://minzdrav.gov.ru/en </a></li>
                  <li>Medical &amp; Dental Council of Nigeria: <a href="http://www.mdcn.gov.ng/" target="_blank">http://www.mdcn.gov.ng/</a></li>
                  <li>Medical Council of Malaysia: <a href="http://www.mma.org.my/" target="_blank">http://www.mma.org.my/</a></li>
                  <li>Bangladesh Dental &amp; Medical Council: <a href="http://www.bmdc.org.bd/" target="_blank">http://www.bmdc.org.bd/</a></li>
                  <li>Lebanese Orders of Physicians: <a href="https://lopbeirut.org/en/homepage-en/" target="_blank">https://lopbeirut.org/en/homepage-en/ </a></li>
                  <li>Pakistan Medical &amp; Dental Council: <a href="https://www.pmc.gov.pk/" target="_blank">https://www.pmc.gov.pk/</a></li>
                  <li>Medical &amp; Dental Council Ghana: <a href="https://www.mdcghana.org/" target="_blank">https://www.mdcghana.org/</a></li>
                  <li>Medical &amp; Dental Council Ghana: <a href="https://www.mdcghana.org/" target="_blank">https://www.mdcghana.org/</a></li>
                  <li>The Medical Council of Thailand: <a href="https://tmc.or.th/En/" target="_blank">https://tmc.or.th/En/</a></li>
                  <li>Nepal Medical Council: <a href="https://www.nmc.org.np/" target="_blank">https://www.nmc.org.np/</a></li>
                </ul>

                <div className='bg-green text-center'>
                  <h3>Important!</h3>
                  <p>For more information about rules and regulations, students must visit or contact Medical councils or General councils of their respective countries before applying to RSMU. RSMU recognized worldwide, the student has to follow the rules of countries only.</p>
                </div>

              </div>
            </div>

          </div>
        </section>


      </div>


    )
  }

}

export default Recognitions_links;