import React, { Component } from "react";
import Socialmedia from './Socialmedia'

export class Footer extends Component {
	render() {
		return (

			<footer className="main-footer dark-section">
				{this.props.children}
				<div className="row row-widgets">
					<div className="columns small-12 medium-6 large-3">
						<section className="widget widget-address">
							<h3 className="widget-title">About Us</h3>
							<p>The Rostov State Medical University is the largest basic training and research center in Southern Russia. The history of the Rostov State Medical University begins in 1915 and since then, the University has been keeping the high reputation and our students and graduates are proud of it.</p>
							<p className="widget-address__info icon-phone-3"><span className="icon-phone-3"></span><a href="tel:+79515173719" target='_blank'> +79515173719</a></p>
							<p className="widget-address__info icon-mail-3"><span className="icon-envelope-open"></span><a href="mailto:contact@rostgmu.net"> contact@rostgmu.net</a></p>
						</section>
					</div>
					<div className="columns small-12 medium-6 large-3">
						<section className="widget widget_pages">
							<h3 className="widget-title">Other Links</h3>
							<ul>
								<li><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
								<li><a href={process.env.PUBLIC_URL + '/study-medicine-in-russia'}>A Little about Russia</a></li>
								<li><a href={process.env.PUBLIC_URL + '/study-medicine-in-russia-rostov-on-don-city'}>About Rostov-on-Don city</a></li>
								<li><a href={process.env.PUBLIC_URL + '/rostov-state-medical-university-history'}>History of RSMU</a></li>
								<li><a href={process.env.PUBLIC_URL + '/chancellors-message'}>Chancellor's Message</a></li>

							</ul>
						</section>
					</div>
					<div className="columns small-12 medium-6 large-3">
						<section className="widget widget_categories">
							<h3 className="widget-title">Other Links</h3>
							<ul>
								<li><a href={process.env.PUBLIC_URL + '/vice-chancellors-message'}>Vice-Chancellor's Message</a></li>
								<li><a href={process.env.PUBLIC_URL + '/recognitions-links'}>Recognitions &amp; Links</a></li>
								<li><a href={process.env.PUBLIC_URL + '/rostov-state-medical-university-faculties-and-fees'}>RSMU Faculties &amp; fees</a></li>
								<li><a href={process.env.PUBLIC_URL + '/preparatory-faculty-on-training-foreign-students'}>Preparatory faculty</a></li>
								<li><a href={process.env.PUBLIC_URL + '/admission-procedure-for-foreign-applicants'}>Admission Procedure</a></li>


							</ul>
						</section>
					</div>
					<div className="columns small-12 medium-6 large-3">
						<section className="widget widget_categories">
							<h3 className="widget-title">Other Links</h3>
							<ul>
								<li><a href={process.env.PUBLIC_URL + '/rostsmu-hostels-for-foreign-students'}>Accommodation Student Life</a></li>
								<li><a href={process.env.PUBLIC_URL + '/frequently-asked-questions'}>FAQ'S</a></li>
								<li><a href={process.env.PUBLIC_URL + '/rostsmu-services-to-foreign-students'}>Services to foreign students</a></li>
								<li><a className="active-in" href={process.env.PUBLIC_URL + '/apply-online-for-study-medicine-in-rostov-state-medical-university-russia'}>Online application form</a></li>
								<li><a href={process.env.PUBLIC_URL + '/representative-of-foreign-students'}>Representatives of foreign students</a></li>
							</ul>
						</section>
					</div>
				</div>


				<div className="row">
					<div className="columns small-12 logo-socials-footer">
						<div className="row align-justify align-middle copyright small-collapse">
							<div className="columns small-9 medium-expand">Copyright &copy; 2024  All Rights Reserved | Site for International Students.</div>
							<div className="columns small-3 medium-expand mr-l-25"><strong>Website Visitors:</strong>
								<a href="https://rostgmu.net/" target="_blank">
									<img src="https://hitwebcounter.com/counter/counter.php?page=8034120&style=0001&nbdigits=5&type=page&initCount=20000" alt='Rostov State Medical University' Alt="web counter" border="0" /></a>
							</div>
						</div>
					</div>
				</div>
				<Socialmedia />
			</footer>



		)
	}
}

export default Footer;	