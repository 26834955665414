import React, { Component } from 'react';
import { Header } from '../layout/Header';
import { Helmet } from "react-helmet";
export class Rostov_state_medical_university_history extends Component {
  render() {

    return (
      <div className="site-main">

        <Helmet>
          <title>History of Rostov State Medical University foundation - RostSMU, Russia</title>
          <meta name="description" content="Rostov State Medical University is the largest medical research center in Southern Russia. In 1994, RostSMU was granted University status." />
          <link rel="canonical" href="https://rostgmu.net/rostov-state-medical-university-history/" />

        </Helmet>


        <section className="hero-section overlay bg-cover">
          <div className="container">
            <div className="hero-slider">
              <div className="hero-slider-item">
                <div className="row">
                  <div className="col-md-8">
                    <h1 className="text-white" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".1">History of RSMU</h1>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row list-items">
              <div className="col-md-12 order-2 order-md-1">
                <img src='images/rostov-state-medical-university-history.webp' className='img-fluid' alt='' />
                <h1>Rostov State Medical University</h1>
                <p>RostSMU is a university of higher professional education and the Ministry of Health and Education of Russia. Rostov State Medical University is also known as RostSMU, RostGMU, РостГМУ), Rostov State Government Medical University.</p>
                <h2>History of Rostov State Medical University, Russia</h2>

                <p>In 1915, the Division of Medicine at the Russian Warsaw University was moved to Rostov on Don and that gave rise to the present-day Rostov State Medical University. It was initially formed as a department and later transformed into a medical Institute in 1930. There were about 295 graduating doctors in the first graduating class of the newly formed school. Rostov State Medical University proves to be one of the oldest Higher Medical educational establishments in Russia. The history of its foundation is significantly different from the history of other medical institutions in Russia. In 1915 the Russian Warsaw University was evacuated from Warsaw to Rostov-on-Don where the University had become the Donskoy University. The best representatives from the medical institutions of Warsaw, Moscow, Saint-Petersburg, and Kazan had gathered in its medical faculty and founded its own unique Rostov medical school that had become one of the best in Russia. Later on, the University&#8217;s medical faculty and Rostov Medical Women Institute were united and became the largest center of medical science and training of medical personnel in the South of Russia.</p>
                <p>Due to the diversity in the students that attended the University, an award of the Order of Friendship was awarded to the University in 1980. With students from all parts of the world represented.</p>
                <p>In 1994, the Rostov State Medical Institute which is the largest basic training, research, and treatment center in southern Russia was renamed the name of Rostov State Medical University.</p>
                <p>In University annually trains over 5000 to 7000 students &#8211; the faculty training and professional retraining of specialists, each year more than 650 people trained in the internship, residency, the graduate school. The university operates the coordinating council for the additional post-graduate professional education of the Southern Federal District. In college widely used modern educational technology, including computer technology.</p>
                <p>The University operates 91 departments, which employs over 800 people teaching staff, more than 130 doctors, professors, about 500 candidates. The university engaged in research and clinical activities, and 31 academicians, corresponding members of the Russian Academy of Medical Sciences. 52 professors are members of other Russian and International academies, 9 employees are Honored Scientists of Russia, 26 &#8211; Honorary Doctor of Russian Federation, and 10 &#8211; Honored Workers of Higher School of the Russian Federation.</p>
                <p>The University has its own clinic, which has 1000 beds, and apart from this has its bases in the largest city hospitals also, which enables the students to acquire sound practical knowledge and skills. University buildings and clinics are provided with up-to-date equipment and other modern appliances. There are 63 departments, 27 of which are clinically based in the best clinics of the city, more than 150 research and educational institutions function in RSMU. Today the University possesses modern educational buildings, clinics, and departments, institutes, and faculties supplied with modern diagnostical and medical equipment for training high-qualified specialists.</p>
                <p>The names of our outstanding ophthalmologists, traumatologists, morphologists, immunologists, orthopedists, cardiologists, physiologists, urologists, nephrologists are well known all over the world.</p>

                <h3>Science &amp; activities:</h3>

                <p>The scientific work is organized and coordinated by the scientific and research activity and is implemented into the departments and courses. The Central Scientific Research Laboratory (CSRL) functions effectively, where a wide range of biochemical, molecular-genetic, morphological, microbiological studies are carrying out. We have The Scientific and Research Institute of Immunology and we are planning to have The Scientific and Research Institute of Urology and Neurology as well as the Centre for Humanitarian Adaptation and International Communication (Centre HAIC) under the guidance of the southern branch of the Russian Academy of Education. The scientific research is done in 5 basic scientific directions: medical-biological problems; ecological-hygienic problems (environmental and health) of public health protection; scientific and organizational work for prevention, diagnosis, and treatment of women, mothers, and children&#8217;s major diseases; scientific and organizational work for prevention, diagnosis, and treatment of surgical diseases; scientific and organizational work for prevention, diagnosis, and treatment of major internal diseases. We got 11 international grants during the last 5 years.</p>
                <p>There are 4 Dissertation Committees for Ph.D. and doctoral dissertation defense.</p>
                <p>The Rostov State Medical University develops international scientific cooperation with Britain, the USA, Germany, France, Bulgaria, Ukraine, Armenia, Moldova, and other countries exchanging scientific staff, students, teachers, highly qualified specialists, and participating in international scientific conferences and congresses.</p>
                <p>The Rostov State Medical University is a member of the North-Caucasian Scientific Centre of High School and is the head of its Medical Department. In recent years the centralization and automation of many diagnostic and medical departments of our University allowed us to concentrate and rationally use the staff, medical equipment, improve the quality of work of each structural unit and create a technology of the continuous medical-diagnostic process.</p>
                <p>The Rectorship puts particular emphasis on the organization of the educational process at the University. Nowadays we implement interactive forms of training and computerization of the educational process. The best professors and doctors of the University have the opportunity to undergo the course of internship in medical clinics and universities in Europe.</p>
                <p>The administration of the University is actively developing and effectively implementing modern programs of medical-social rehabilitation of children and adolescents in practical healthcare. Under the realization of the educational task of the priority, national projects in the sphere of health care physicians for the Rostov Region and the whole Caucasian region are successfully trained at our University.</p>



              </div>
            </div>

            <div className="row list-items align-items-center">
              <div className="col-md-5 order-2 order-md-1">

                <h3>RostSMU History &amp; Excellence:</h3>
                <p><iframe title="Rostov State Medical University | 130 Yrs Of Excellence | Choose The Best For You | MBBS In Russia" width="100%" height="360" src="https://www.youtube.com/embed/d1C_DFa5v7M?start=68&#038;feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>

              </div>
              <div className="col-md-7 order-2 order-md-1">
                <div className='bg-green text-center'>
                  <h3>RostSMU welcomes International students<br /> from all over the world </h3>
                  <p>Study Medicine in Russia for foreigners | Study MBBS in Russia-Rostov State Medical University | the Top Medical University of Russia | Study MBBS Abroad in Russia | MBBS in Russia for Indian Students | Study MBBS in Russia for Indian students | 2024 Medical Admissions Russia | Top University of the Russian Federation | Largest Medical Research Center of South Russia</p>
                </div>
              </div>

            </div>


          </div>
        </section>


      </div>


    )
  }

}

export default Rostov_state_medical_university_history;