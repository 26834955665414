import React, { Component } from 'react'

export class News extends Component {
  render() {

    return (
      <div className="row">
        <div className="columns small-12">
          <div className="row large-collapse services-links">
            <div className="marquee" id="mycrawler">
              <marquee scrollamount="3" direction="left" onMouseOver="stop()" onMouseOut="start();">	<span className="text-white"><span className="blink-text">Breaking News:</span>  Dear aspirants, Admissions are open for<span className="blink-text"> 2024</span> academic year. Admissions to <span className="blink-text">RostSMU are strictly based on merit. Limited seats available for all faculties</span>. For General Medicine Faculty <span className="blink-text">(MBBS Program)</span> we have complete six years English Medium. Get direct admission through our official representative only.</span></marquee>
            </div>
          </div>
        </div>
      </div>

    )
  }
}
export default News;