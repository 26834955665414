import React, { Component } from 'react'

export class UpcomingNews extends Component {
    render() {
        return (
            <div className="site-main">
                <section className="hero-section overlay bg-cover">
                    <div className="container">
                        <div className="hero-slider">
                            <div className="hero-slider-item">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h1 className="text-white" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".1">Upcoming News</h1>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                <section className="section">
                    <div className="container">
                        <div className="row list-items">
                            <div className="col-md-12 order-2 order-md-1">

                                <h6 className='text-red'>Date: 01.09.2024</h6>
                                <h1><strong>Rostov State Medical University hosted "Initiation into students"</strong></h1>

                                <p>Initiation into students took place at Rostov State Medical University. 1842 applicants have become part of our large friendly student family.</p>

                                <p>Today, <strong>01/09/2024, the solemn event "Freshman Day"</strong> was held, organized by the Trade Union Organization of Rostov State Medical University!</p>
                                <p>Welcoming remarks and best wishes were made by Andrey Vladimirovich Puchkov, Deputy Governor of the Rostov Region, and Yury Viktorovich Kobzev, Minister of Health of the Rostov Region.</p>

                                <p>The oath for the dedication of first-year students to medical students was read out by the vice-rector for scientific work - <strong>Drobotya Natalia Viktorovna!</strong></p>
                                <p><strong>The Vice-Rector for Education of Foreign Citizens and International Cooperation Gafiyatullina Gyuzyal Shamilevna congratulated the first-year foreign students.</strong></p>

                                <p>We sincerely congratulate our students, let your studies give you many interesting discoveries and necessary knowledge, we wish you to work hard and confidently go towards achieving all your goals!</p>

                                <p>In total, <strong>from September 1, 9556</strong> students of Rostov State Medical University have started classes.</p>

                            </div>
                        </div>

                        <div className="row list-items align-items-center">
                            <div className="col-md-6 order-2 order-md-1">
                                <img src='images/DSCF3917.jpg' className='img-fluid' alt='' />           </div>
                            <div className="col-md-6 order-2 order-md-1">
                                <img src='images/DSCF3905.jpg' className='img-fluid' alt='' />     </div>
                        </div>



                    </div>
                </section>
            </div>
        )
    }
}

export default UpcomingNews
