import React, { Component } from 'react';
import { Header } from '../layout/Header';
import { Helmet } from "react-helmet";
export class About_us extends Component {
  render() {

    return (
      <div className="site-main">
        <Helmet>
          <title>medical study in Russia, study MBBS abroad</title>
          <meta name="description" content="International students wishing to study at RostSMU have to undergo the admission procedure, which is quite simple. After submitting the application form our official representative will contact you." />
          <link rel="canonical" href="https://rostgmu.net/about-us/" />
        </Helmet>


        <section className="hero-section overlay bg-cover">
          <div className="container">
            <div className="hero-slider">
              <div className="hero-slider-item">
                <div className="row">
                  <div className="col-md-8">
                    <h1 className="text-white" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".1">About Us</h1>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row list-items">
              <div className="col-md-12 order-2 order-md-1">
                <h2 className='text-center'>”Welcome to fulfill your dreams to become a successful doctor, one and only best medical university of Russia – RostSMU”</h2>
                <h1>ROSTOV STATE MEDICAL UNIVERSITY, RUSSIA | Study MBBS in Russia</h1>
                <p>Rostov State Medical University embraces a special mission to train practitioners from different countries of the world and to contribute to the improvements of health education, research, and prevention for the local population, choosing the university means plentiful opportunities for gaining professional expression and carrier pursuit. The Rostov State Medical University is accredited by the Russian Health Ministry for education for the teaching of both Russian and overseas students.</p>

                <p>“We welcome you to our modernized university which will be running its courses in English/Russian Medium and will fulfill your dreams of becoming a successful doctor. Advanced knowledge through teaching, learning, and research. We are delighted that you are interested in Rostov State Medical University and look forward to receiving  your completed application.”</p>

                <ul>
                  <li><a href="https://rrecrostov.com/">Rostov State Medical University</a> is one of the best Medical University in Russia.</li>
                  <li>The high quality of training: the highest fourth level of state accreditation.</li>
                  <li>The opportunity to get the qualification in different professional levels from Bachelor’s Degree to Doctor of Medicine.</li>
                  <li>International students from more than 30 countries in Asia, Africa, UAE, Europe.</li>
                  <li>Post-graduate studying on 55 specialties at internship and mastership.</li>
                  <li>Acknowledgment of the University diploma (by WHO, GMC, USMLE, NMC, IMED, ECFMG, and other organizations).</li>
                  <li>Cooperation and joint researches with many scientific institutions of Russia and all over the world.</li>
                  <li>A modern well-developed campus including five academic buildings, clinics, a library, 5 student hostels, a gym, several canteens, etc.</li>
                  <li><a href="https://thedoctorsiea.in/rostov-state-medical-university/">Rostov State Medical University</a> is the best place for those who are willing to become a doctor with excellence and complete knowledge of their profession.</li>
                  <li>The <a href="https://rostovstatemedicaluniversity.com/">Rostov State Medical University</a> today is the biggest educational, scientific, and medical complex in Russia for training, certification, and continuing professional development of medical and pharmaceutical workers that contribute significantly to domestic and global medicine.</li>
                </ul>

              </div>
            </div>

            <div className="row list-items align-items-center">
              <div className="col-md-6 order-2 order-md-1 text-center">
                <img src='images/abt1.jpg' className='img-fluid' alt='' />
              </div>
              <div className="col-md-6 order-2 order-md-1 text-center">
                <img src='images/abt2.jpg' className='img-fluid' alt='' />
              </div>
            </div>
            <div className="row list-items align-items-center">
              <div className="col-md-6 order-2 order-md-1 text-center">
                <img src='images/abt3.jpg' className='img-fluid' alt='' />
              </div>
              <div className="col-md-6 order-2 order-md-1 text-center">
                <img src='images/abt4.jpg' className='img-fluid' alt='' />
              </div>
            </div>

            <div className="row list-items align-items-center">
              <div className="col-md-6 order-2 order-md-1 text-center">
                <div className='bg-green text-center'>
                  <h3>RostSMU listed in Top 20 Medical Universities of Russia </h3>
                  <p>Admission is open for study Medicine (MBBS) in Russia for foreign students: students from Asia, Africa, Arab &amp; European continents can apply for the 2024 academic year.</p>
                </div>
              </div>
              <div className="col-md-6 order-2 order-md-1 text-center">
                <div className='bg-red text-center'>
                  <h3>” IT’S TIME TO STUDY MEDICINE IN ROSTSMU, RUSSIA ”</h3>
                </div>
              </div>
            </div>


          </div>
        </section>


      </div>


    )
  }

}

export default About_us;